import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PowerSavings from '../../templates/power-savings.js';
export const _frontmatter = {
  "title": "Power Savings"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SavingsForm = makeShortcode("SavingsForm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = PowerSavings;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SavingsForm formName="Power Savings" mdxType="SavingsForm">
                    <h1 style={{
        fontSize: '2.5rem',
        marginBottom: 20
      }}>
                        Calculate Your Savings
                    </h1>
                    <p style={{
        fontSize: '1.5rem',
        marginBottom: 50
      }}>
                        See How Much You Could Save If You Qualify For The
                        No-Cost Solar Program
                    </p>
    </SavingsForm>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      